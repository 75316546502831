<mat-divider
  class="!mx-3 min-w-9 rounded-full border-[2px] !border-sky-900"
></mat-divider>

<mat-expansion-panel
  class="mat-elevation-z0 !mt-2"
  *ngIf="list && list.length > 0 && show"
  [expanded]="getExpandedState()"
  (expandedChange)="onExpandedChange($event)"
>
  <mat-expansion-panel-header
    *ngIf="toggleSide"
    class="!h-7"
  >
    <mat-panel-title class="!ml-4">
      <mat-icon
        [svgIcon]="headerIcon"
        *ngIf="headerIcon"
      ></mat-icon>
      <span class="header-text !text-white">{{ headerName }}</span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-nav-list>
    <ng-container *ngFor="let item of list">
      <!--If item have children, it will work as expansion panel-->
      <mat-expansion-panel *ngIf="item.children && item.children.length > 0">
        <mat-expansion-panel-header
          [ngClass]="toggleSide ? '!ml-4 !h-8' : '!h-12'"
          class="!transition-none"
        >
          <mat-panel-title>
            <mat-icon
              matListItemIcon
              [svgIcon]="item.icon"
              [ngClass]="{ '!text-white': !item.color }"
              [style.color]="item.color + ' !important'"
              matTooltip="{{ item.name }}"
              class="!h-5 !w-5"
              [ngClass]="!toggleSide ? '!ml-[0.7rem]' : '!ml-5 !mr-[10px]'"
            ></mat-icon>
            <span *ngIf="toggleSide">{{ item.name }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list
          [ngClass]="
            toggleSide ? '!ml-4 !w-[calc(100%-35px)]' : '!w-[calc(100%-4px)]'
          "
        >
          <mat-list-item
            *ngFor="let children of item.children"
            class="mx-auto !h-8"
            [routerLink]="children.path"
            routerLinkActive=" !bg-[#1A9AF1] !rounded-md"
            trackClick="choose-profile-from-team"
            [ngClass]="!toggleSide ? 'max-w-10' : 'max-w-52'"
          >
            <mat-icon
              matListItemIcon
              [svgIcon]="children.icon"
              [ngClass]="{ '!text-white': !item.color }"
              [style.color]="children.color + ' !important'"
              matTooltip="{{ children.name }}"
              class="!h-5 !w-5 !text-white"
              [ngClass]="!toggleSide ? '!ml-[0.7rem]' : '!ml-5 !mr-[10px]'"
            ></mat-icon>

            <a
              matListItemTitle
              *ngIf="toggleSide"
              >{{ children.name }}</a
            >
          </mat-list-item>
        </mat-nav-list>
      </mat-expansion-panel>

      <!--If the item does not have children, it will work as a normal list item.-->
      <mat-list-item
        *ngIf="
          (!item.children || item.children.length === 0) &&
          (item.flag | async) !== false
        "
        class="mx-auto !h-8"
        trackClick="choose-team-from-sidenav"
        [ngClass]="toggleSide ? 'max-w-52' : 'max-w-10'"
        [routerLink]="item.path"
        routerLinkActive="!bg-[#1A9AF1] !rounded-md"
        [matTooltip]="
          isProfileNotAssigned && item.name === 'Teams'
            ? 'You have unassigned profiles'
            : item.name === 'Profiles & Organizations' && isAuthorNotAssigned
            ? 'You have unassigned authors'
            : item.name
        "
      >
        <mat-icon
          matListItemIcon
          [svgIcon]="item.icon"
          [ngClass]="{ '!text-white': !item.color }"
          [style.color]="item.color + ' !important'"
          class="relative !h-5 !w-5 !text-white"
          [ngClass]="!toggleSide ? '!ml-[0.65rem]' : '!ml-5 !mr-[10px]'"
          ><div
            class="absolute left-0 top-0 h-2 w-2 rounded-full bg-red-500"
            *ngIf="
              (item.name === 'Teams' && isProfileNotAssigned) ||
              (item.name === 'Profiles & Organizations' && isAuthorNotAssigned)
            "
          ></div
        ></mat-icon>

        <a
          matListItemTitle
          *ngIf="toggleSide"
          >{{ item.name }}</a
        >
      </mat-list-item>
    </ng-container>
  </mat-nav-list>
</mat-expansion-panel>

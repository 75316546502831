@media print {
  .printing-section {
    page-break-before: always;
  }

  .printing-section:first-of-type {
    page-break-before: avoid !important;
  }
}

.printing-section {
  color: #1e293b;
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '@codenteam/core/routes';
import { SideNavData } from './sidenav-list-data.dto';
import { BehaviorSubject, combineLatest, map, of } from 'rxjs';
import { FeatureFlagService } from 'packages/core/feature-flags/frontend/src/lib/feature-flag.service';
import { OverallRunStatus } from '@codenteam/portal/graphql';

@Injectable({
  providedIn: 'root',
})
export class PrepareDataService {
  private runId$ = new BehaviorSubject<string | null>(null);
  private teamsItems$ = new BehaviorSubject<SideNavData[]>([]);
  private organizations$ = new BehaviorSubject<SideNavData[]>([]);
  public canEdit$ = new BehaviorSubject<boolean>(true);
  private overAllAnalysisStatus$ = new BehaviorSubject<OverallRunStatus | null>(
    null
  );

  setRunId(runId: string) {
    this.runId$.next(runId);
  }

  setTeamsItems(teamsItems: SideNavData[]) {
    this.teamsItems$.next(teamsItems);
  }

  setCanEdit(value: boolean) {
    this.canEdit$.next(value);
  }

  setOrganizations(organizations: SideNavData[]) {
    this.organizations$.next(organizations);
  }

  setStatus(value: OverallRunStatus) {
    this.overAllAnalysisStatus$.next(value);
  }

  constructor(
    private router: Router,
    private routesService: RoutesService,
    private featureFlagService: FeatureFlagService
  ) {}

  editsMenu$ = this.runId$.pipe(
    map((runId) =>
      runId
        ? [
            {
              icon: 'edit-files',
              name: 'Files',
              path: this.routesService.absolute(
                this.routesService.excludeFiles(runId)
              ),
              flag: this.featureFlagService.getFlag$('manual-files-exclusion'),
            },
            {
              icon: 'edit-teams',
              name: 'Teams',
              path: this.routesService.absolute(
                this.routesService.createTeams(runId)
              ),
            },
            {
              icon: 'edit-profiles-and-org',
              name: 'Profiles & Organizations',
              path: this.routesService.absolute(
                this.routesService.createDevelopers(runId)
              ),
            },
          ]
        : []
    )
  );
  assessmentsMenu$ = combineLatest([
    this.runId$,
    this.overAllAnalysisStatus$,
    this.featureFlagService.getFlag$('SBOM'),
  ]).pipe(
    map(([runId, overallStatus, sbomFlag]) => {
      return runId
        ? [
            {
              icon: 'risks-icon',
              name: 'Risks',
              path: this.routesService.absolute(
                this.routesService.analysisRisks(runId)
              ),
              flag: this.featureFlagService.getFlag$('risks-page'),
            },
            {
              icon: 'lic-scan-icon',
              name: 'Dependencies',
              path: this.routesService.absolute(
                this.routesService.dependencies(runId)
              ),
              flag: of(overallStatus === OverallRunStatus.Ready && sbomFlag),
            },
            // {
            //   icon: 'report-icon',
            //   name: 'Report',
            //   dataTest: 'code-analysis-report',
            //   path: this.routesService.absolute(
            //     this.routesService.report(runId)
            //   ),
            // },
          ]
        : [];
    })
  );

  ownershipMenu$ = combineLatest([
    this.runId$,
    this.teamsItems$,
    this.organizations$,
  ]).pipe(
    map(([runId, teamsItems, organizations]) => {
      if (!runId) return [];
      return [
        {
          icon: 'code',
          name: 'Code',
          dataTest: 'code-analysis',
          path: this.routesService.absolute(
            this.routesService.codeAnalysis(runId)
          ),
        },
        {
          icon: 'team',
          name: 'Teams',
          children: teamsItems,
        },
        {
          icon: 'edit-profiles-and-org',
          name: 'Organizations',
          children: organizations,
          flag: this.featureFlagService.getFlag$('organizations'),
        },
      ];
    })
  );
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import {
  NameValue,
  Organization,
  StateOptions,
  User,
} from '@codenteam/portal/graphql';
import { Team } from '@codenteam/portal/graphql';
import { HttpErrorResponse } from '@angular/common/http';
import { cloneDeep } from 'lodash';
import { RoutesService } from '@codenteam/core/routes';
import { SideNavData } from './sidenav-list-data.dto';
import { ApiService } from '../../core/api.service';
import { SidenavService } from './sidenav.service';
import { TourService } from '../../tour.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SearchComponent } from '../../wizard/search-form/search.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { routeEnhanced$ } from '../../core/route-reader.service';
import { OrganizationsService } from '../../core/organizations.service';
import { SharedModule } from '../../shared.module';
import { AnalyticsService } from '../../core/analytics/analytics.service.abstract';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { TimeAgoModule } from '@codenteam/ui/time-ago/time-ago.module';
import { OverallRunStatus } from '@codenteam/core/utils/dtos/overall-run-status.dto';
import { GenericExpansionPanelComponent } from '@codenteam/ui/generic-expansion-panel/generic-expansion-panel.component';
import { PrepareDataService } from './prepare-data.service';
import { NotificationsComponent } from '../notifications/notifications.component';

@Component({
  selector: 'codenteam-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatSidenavModule,
    SearchComponent,
    MatToolbarModule,
    RouterModule,
    MatListModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    LetDirective,
    SharedModule,
    MatExpansionModule,
    TimeAgoModule,
    GenericExpansionPanelComponent,
    NotificationsComponent,
  ],
})
export class MainLayoutComponent implements OnInit {
  user: User;
  email: string;
  name: string;
  runId: string;
  canEdit: boolean;
  runId$ = routeEnhanced$('runId');
  overallRunStatus: OverallRunStatus;
  runstatus = OverallRunStatus;
  toggleSide: boolean;
  isAuthorNotAssigned: boolean;
  isProfileNotAssigned: boolean;
  maxDisplayed: number = 5;

  projectUuid: string = null;
  startupUuid: string = null;

  searchExpand = false;

  editsMenu$ = this.prepareDataService.editsMenu$;
  assessmentsMenu$ = this.prepareDataService.assessmentsMenu$;
  ownershipMenu$ = this.prepareDataService.ownershipMenu$;
  canEdit$ = this.prepareDataService.canEdit$;

  teamsItems: SideNavData[];

  organizations: SideNavData[];
  constructor(
    private apiService: ApiService,
    private router: Router,
    private activated: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private tourService: TourService,
    public sidenavService: SidenavService,
    private routesService: RoutesService,
    private organizationsService: OrganizationsService,
    private analyticsService: AnalyticsService,
    public prepareDataService: PrepareDataService
  ) {
    this.email = 'email@email.com';
    this.name = 'User Name';
    this.runId = '';
    this.sidenavService.sidenavExpanded$.subscribe((newValue) => {
      this.toggleSide = newValue;
    });
    this.getUserInfo();
  }

  getUserInfo() {
    this.apiService.getUserInfo().subscribe({
      next: (res) => {
        if (res && res !== null) {
          this.user = res;
          this.email = res.email;
          this.name = this.email.substring(0, this.email.indexOf('@'));
          this.analyticsService.identify(res.id.toString(), {});
        } else {
          this.router.navigate(this.routesService.login());
          return;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.router.navigate(this.routesService.login());
      },
    });
  }

  async checkRunIdChangeInRoute(newRunId: string) {
    this.runId = newRunId;
    if (this.runId) {
      this.checkRunEditingAccessibility();
      this.isAnyAuthorNotAssignedToProfile(this.runId);
      this.isAnyProfileNotAssignedToTeam(this.runId);
    }
    if (this.runId) {
      this.apiService.getAllTeams(this.runId).subscribe((res) => {
        this.teamsItems = res
          .filter((team) => team.profiles && team.profiles.length > 0)
          .map((teamItem) => ({
            name: teamItem.name,
            color: teamItem.color,
            path: this.routesService.absolute(
              this.routesService.teamAnalysis(this.runId, teamItem.id)
            ),
            icon: 'team',
          }));
        this.initializeData();
        this.getOrganizations(this.runId);
        this.changeDetector.markForCheck();
      });

      this.apiService
        .getRunOverallStatusWithParent(this.runId)
        .subscribe((res) => {
          this.overallRunStatus = res.runProgressStatuses.overallRunStatus;
          this.prepareDataService.setStatus(
            res.runProgressStatuses.overallRunStatus
          );
          this.projectUuid = res.projectUuid;
          this.startupUuid = res.startupUuid;
        });
    }
  }
  ngOnInit(): void {
    this.runId$.subscribe((runId) => {
      this.checkRunIdChangeInRoute(runId);
    });
  }
  initializeData() {
    this.prepareDataService.setRunId(this.runId);
    this.prepareDataService.setTeamsItems(this.teamsItems);
  }
  onNavigate(): void {
    this.toggleSide = false;
  }
  get runHomeRoute(): any[] | string {
    return this.routesService.runHome(this.runId);
  }
  getOrganizations(runId: string) {
    this.organizationsService
      .getAllOrganizationsBasic(runId)
      .subscribe((orgs) => {
        if (!orgs) return;
        this.organizations = orgs.map((orgItem) => ({
          name: orgItem.name,
          color: orgItem.color,
          path: this.routesService.absolute(
            this.routesService.organizationAnalysis(this.runId, orgItem.id)
          ),
          icon: 'organization-icon',
        }));
        this.prepareDataService.setOrganizations(this.organizations);
      });
  }

  checkRunEditingAccessibility() {
    this.apiService.editingRunAccessibility(this.runId).subscribe({
      next: (res) => {
        this.canEdit = res;
        this.prepareDataService.setCanEdit(res);
      },
      error: (err: HttpErrorResponse) => {
        this.router.navigate(this.routesService.runsList());
      },
    });
  }

  navigateToUrl(url: string) {
    this.router.navigate([url]).then((res) => {
      this.toggleSide = false;
    });
  }
  goToRunParent() {
    if (this.projectUuid) {
      this.router.navigate(this.routesService.singleProject(this.projectUuid));
    } else if (this.startupUuid) {
      this.router.navigate(this.routesService.singleStartup(this.startupUuid));
    } else {
      this.router.navigate(this.routesService.runsList());
    }
  }

  setToggleSide(value: boolean) {
    this.toggleSide = value;
  }
  gotoScans() {
    this.router.navigate(this.routesService.scansList());
  }

  goToInvestorHubPage() {
    this.router.navigate(this.routesService.investorHub());
  }

  goToProjectsHubPage() {
    this.router.navigate(this.routesService.projectHub());
  }

  gotoRunsList() {
    this.router.navigate(this.routesService.runsList()).then((res) => {
      this.toggleSide = false;
      this.resetSideNav();
    });
  }

  gotoReport() {
    this.router.navigate(this.routesService.report2()).then((res) => {
      this.toggleSide = false;
      this.resetSideNav();
    });
  }

  gotoSharedRunsList() {
    this.router
      .navigate(this.routesService.runsList(), {
        queryParams: { type: 'shared' },
      })
      .then((res) => {
        this.toggleSide = false;
        this.resetSideNav();
      });
  }
  gotoInvestorsHub() {
    this.router.navigate(this.routesService.investorHub()).then((res) => {
      this.toggleSide = false;
      this.resetSideNav();
    });
  }
  gotoHomePage() {
    this.router.navigate(this.routesService.home()).then((res) => {
      this.toggleSide = false;
      this.resetSideNav();
    });
  }

  resetSideNav() {
    this.runId = '';
    this.teamsItems = [];
    this.changeDetector.markForCheck();
  }

  gotoRun(uuid: string) {
    this.router.navigate(this.routesService.runHome(uuid)).then((res) => {
      this.toggleSide = false;
    });
  }

  startTour() {
    this.toggleSide = false;
    this.tourService.resetTour();
    this.tourService.removeAllToursFromLocalStorage();
    if (
      this.router.url ===
      this.routesService.absolute(this.routesService.runsList())
    ) {
      location.reload();
    } else {
      this.router.navigate(this.routesService.runsList());
    }
  }
  gotoAccountUsersPage() {
    this.router.navigate(this.routesService.settings()).then((res) => {
      this.toggleSide = false;
    });
  }

  goToReportPage() {
    this.router
      .navigate(this.routesService.report(this.runId))
      .then((result) => {
        this.toggleSide = false;
      });
  }
  goToHiringJobListingPage() {
    this.router.navigate(this.routesService.jobListing()).then((result) => {
      this.toggleSide = false;
    });
  }

  isAnyProfileNotAssignedToTeam(runId: string) {
    this.apiService.isAnyProfileNotAssignedToTeam(runId).subscribe((res) => {
      this.isProfileNotAssigned = res;
    });
  }

  isAnyAuthorNotAssignedToProfile(runId: string) {
    this.apiService.isAnyAuthorNotAssignedToProfile(runId).subscribe((res) => {
      this.isAuthorNotAssigned = res;
    });
  }
  logOut() {
    this.apiService.logOut().subscribe({
      next: () => {
        this.analyticsService.identify(null, {});
        this.router.navigate(this.routesService.login());
      },
    });
  }
}

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpResponse,
  HttpEventType,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { ErrorSnackbarService } from './error-snackbar.service';
import { ErrorTranslationService } from './error-translation.service';
import { catchError, tap, throwError } from 'rxjs';
import { ErrorCode } from '@codenteam/core/errors';
import { ErrorHandlerService, ErrorObject } from './error-handler.service';
import { AccountExceededServiceAndAccessibility } from '../account-exceeded.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    // private snackbarService: ErrorSnackbarService,
    private errorHandlerService: ErrorHandlerService,
    private errorTranslator: ErrorTranslationService,
    private accountExceededService: AccountExceededServiceAndAccessibility
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.Response) {
          if (event.body && event.body.errors && event.body.errors.length > 0) {
            const code =
              event.body.errors[0].extensions?.exception?.code ||
              ErrorCode.INTERNAL_SERVER_ERROR;

            /**
             * get message, if no message is found get the default message for this code from errorTranslator service
             */
            const message =
              event.body.errors[0].extensions?.exception?.message ||
              event.body.errors[0].message ||
              this.errorTranslator.getMessage(code);

            const error: ErrorObject = { errorCode: code, message: message };
            /** Workaround for quota
             * TODO should be fixed (currently using another global error handler)
             */
            if (error.errorCode !== ErrorCode.QUOTE_EXCEEDED_ERROR) {
              this.errorHandlerService.showError(error);
            } else {
              this.accountExceededService.handelAccountExceededAndAccessibilityError();
            }

            // this.snackbarService.showError(message, code);
          }
        }
      })
    );
  }
}

<ng-container>
  <button
    [matMenuTriggerFor]="notificationsMenu"
    (menuOpened)="isMenuOpen = true"
    (menuClosed)="isMenuOpen = false"
  >
    <div
      matBadge="{{
        (notificationsData$ | async)?.unreadNotificationsCount > 99
          ? '99+'
          : (notificationsData$ | async)?.unreadNotificationsCount
      }}"
      matBadgeHidden="{{
        (notificationsData$ | async)?.unreadNotificationsCount === 0 ||
          isMenuOpen
      }}"
      matBadgeSize="small"
      class="custom-badge round relative ml-3 h-10 w-10 place-content-center place-items-center overflow-hidden rounded-full py-2 text-slate-800"
      [ngClass]="
        isMenuOpen ? 'bg-blue-400 ' : 'bg-slate-600 hover:bg-slate-500'
      "
    >
      <mat-icon>notifications</mat-icon>
    </div>
  </button>
  <mat-menu
    #notificationsMenu="matMenu"
    class="!max-w-[250px] !rounded-xl !bg-slate-600 px-[18px] py-3 sm:!max-w-[400px]"
    ><div class="font-mada mb-1 text-2xl font-semibold text-slate-900">
      Notifications
    </div>
    <ng-container *ngrxLet="notificationsData$ as notificationsData">
      <div
        class="font-mada flex h-40 w-[200px] items-center justify-center text-xl text-gray-400 sm:w-[350px] sm:text-2xl"
        *ngIf="notificationsData?.notifications?.length === 0"
      >
        No Notifications Yet
      </div>
      <div
        class="flex max-h-[400px] flex-col overflow-y-auto"
        *ngIf="notificationsData?.notifications.length > 0"
      >
        <div
          class="font-mada flex flex-col text-white"
          *ngFor="
            let notification of notificationsData?.notifications;
            let i = index
          "
        >
          <button
            class="my-1 rounded-lg px-2 py-2 hover:bg-slate-500"
            (click)="
              notificationNavigate(
                notificationsData.notifications,
                i,
                notificationsData.unreadNotificationsCount
              )
            "
          >
            <div class="flex items-center justify-between">
              <div class="ml-2 text-xs text-zinc-400">
                {{ notification.createdAt | timeAgo }}
              </div>
              <mat-icon
                class="mt-0.5 !text-sm text-blue-400"
                *ngIf="!notification.readAt"
                >circle</mat-icon
              >
            </div>
            <div class="break-words text-start font-medium sm:text-[17px]">
              {{ notification.data.title }}
            </div>
            <div class="break-words text-start text-sm leading-[19px]">
              {{ notification.data.message }}
            </div>
          </button>
          <mat-divider class="text-blue-400"></mat-divider>
        </div>
      </div>
      <div
        class="font-mada mt-3 flex justify-between text-sm font-medium leading-[18px] text-slate-800"
        *ngIf="notificationsData?.notifications?.length > 0"
      >
        <!-- <button class="underline underline-offset-4 hover:text-slate-900">
      Show more</button
    > -->
        <button
          class="underline underline-offset-4 hover:text-slate-900"
          (click)="clearNotifications()"
        >
          Clear All
        </button>
      </div>
    </ng-container>
  </mat-menu>
</ng-container>
